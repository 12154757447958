import React, { useEffect, useState } from 'react';

import { Breadcrumb, BreadcrumbProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { HomeOutlined } from '@ant-design/icons';

import type { BreadcrumbItemType, ItemRenderFunction } from './Breadcrumbs.types';
import { getBreadcrumbsMap } from './breadcrumbsMap';
import { matchPath } from './matchPath';
import { Wrapper } from './Breadscrumbs.styled';

const homeBreadcrumb = {
  path: '/',
  title: <HomeOutlined />,
};

const removeDuplicateSegments = (path: string): string => {
  const pathSegments = path.split('/');
  const uniqueSegments: string[] = [];

  pathSegments.forEach((segment) => {
    if (uniqueSegments[uniqueSegments.length - 1] !== segment) {
      uniqueSegments.push(segment);
    }
  });

  return uniqueSegments.join('/');
};

const AppBreadcrumbs: React.FC = () => {
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItemType[]>([]);
  const [breadcrumbsMap, setBreadcrumbsMap] = useState<{
    [key: string]: string | ((param: string) => Promise<string | undefined>);
  }>({});

  const itemRender: ItemRenderFunction = (route, _, routes, paths) => {
    const isLast = route.path === routes[routes.length - 1]?.path;

    const cleanedPath = removeDuplicateSegments(paths.join('/'));

    return isLast ? <span>{route.title}</span> : <Link to={cleanedPath}>{route.title}</Link>;
  };

  useEffect(() => {
    const fetchBreadcrumbsMap = async () => {
      const map = getBreadcrumbsMap();
      setBreadcrumbsMap(map);
    };

    fetchBreadcrumbsMap();
  }, []);

  useEffect(() => {
    const generateBreadcrumbs = async () => {
      const breadcrumbList: BreadcrumbItemType[] = [];
      const pathSnippets = location.pathname.split('/').filter((i) => i);

      let accumulatedPath = '';
      for (let i = 0; i < pathSnippets.length; i++) {
        accumulatedPath += `/${pathSnippets[i]}`;

        if (breadcrumbsMap[accumulatedPath]) {
          breadcrumbList.push({
            path: accumulatedPath,
            title: breadcrumbsMap[accumulatedPath] as React.ReactNode,
          });
        }

        for (const pathPattern in breadcrumbsMap) {
          const params = matchPath(pathPattern, accumulatedPath);
          if (params && typeof breadcrumbsMap[pathPattern] === 'function') {
            const breadcrumbName = await (
              breadcrumbsMap[pathPattern] as (param: string) => Promise<string | undefined>
            )(params[Object.keys(params)[0]]);

            const existing = breadcrumbList.find((item) => item.title === breadcrumbName);
            if (!existing) {
              breadcrumbList.push({
                path: accumulatedPath,
                title: breadcrumbName as React.ReactNode,
              });
            }
          }
        }
      }

      setBreadcrumbItems([homeBreadcrumb, ...breadcrumbList]);
    };

    if (Object.keys(breadcrumbsMap).length > 0) {
      generateBreadcrumbs();
    }
  }, [location, breadcrumbsMap]);

  return (
    <Wrapper>
      <Breadcrumb itemRender={itemRender as BreadcrumbProps['itemRender']} items={breadcrumbItems} />
    </Wrapper>
  );
};

export default AppBreadcrumbs;
