import '@/locales/i18n';

import React from 'react';

import { App as Antd } from 'antd';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './contexts/AuthContext/AuthContext';
import { router } from './router';

import * as Sentry from '@sentry/react';
import Auth0Provider from './components/AuthWrapper/Auth0Provider';

export const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://520371275e2c7bb21bf67b160a607bc5@o188333.ingest.us.sentry.io/4507929729302528',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/access.wicketsoft\.com\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Antd>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </Auth0Provider>
      </QueryClientProvider>
    </Antd>
  </React.StrictMode>,
);
