import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppNavigation } from 'src/routes';
import { useAuth, UserRole } from '../contexts/AuthContext';
import { useAuth0 } from '@auth0/auth0-react';

interface ProtectedRoutesProps {
  requiredRoles?: UserRole[];
  children?: React.ReactNode;
}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ requiredRoles, children }) => {
  const location = useLocation();
  const { roles, isAuthenticated: internalIsAuthenticated } = useAuth();
  const { isAuthenticated } = useAuth0();

  const { routesMap } = useAppNavigation();
  const hasRequiredRoles = requiredRoles ? requiredRoles.some((role) => roles.includes(role)) : true;

  // if the user is authenticated and has the required roles, render the children
  if (internalIsAuthenticated || isAuthenticated) {
    if (!hasRequiredRoles) {
      return <Navigate to={routesMap.noPermission} replace state={{ from: location }} />;
    }
    return children ? <>{children}</> : <Outlet />;
  }

  return <Navigate to={routesMap.login} replace state={{ from: location }} />;
};
