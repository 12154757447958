import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

// import TicketOutlined from '@/assets/icons/TicketOutlined.svg';  // both of these have thier path tags extracted and used here for antd to theme
// import TicketOutlinedAlt from '@/assets/icons/TicketOutlinedAlt.svg'; // both of these have thier path tags extracted and used here for antd to theme
import {
  CalendarOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  FileOutlined,
  IdcardOutlined,
  LaptopOutlined,
  LayoutOutlined,
  LineChartOutlined,
  LinkOutlined,
  LockOutlined,
  MailOutlined,
  SettingOutlined,
  TabletOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

export const useSidebarItems = (): MenuItem[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'ticketLogs',
      icon: (
        <span className="anticon anticon-credit-card ant-menu-item-icon">
          <svg width="1em" height="1em" viewBox="0 0 15 16" fill="currentColor">
            <path d="M12.6562 13.3125H2.34375C1.39443 13.3125 0.625 12.5431 0.625 11.5938V9.34677L1.25292 9.34849C1.88313 9.33188 2.39073 8.81396 2.39073 8.17974C2.39073 7.54552 1.88427 7.02875 1.25521 7.01099L0.625 7.01271V4.71875C0.625 3.76943 1.39443 3 2.34375 3H12.6562C13.6056 3 14.375 3.76943 14.375 4.71875V7.01042H13.8663C13.2951 7.01042 12.7651 7.39943 12.6723 7.9626C12.5543 8.6799 13.1066 9.30208 13.8021 9.30208H14.375V11.5938C14.375 12.5431 13.6056 13.3125 12.6562 13.3125ZM2.34375 12.1667H12.6562C12.9725 12.1667 13.2292 11.91 13.2292 11.5938V10.3757C12.242 10.1208 11.5104 9.22245 11.5104 8.15625C11.5104 7.09005 12.242 6.19229 13.2292 5.93677V4.71875C13.2292 4.4025 12.9725 4.14583 12.6562 4.14583H2.34375C2.0275 4.14583 1.77083 4.4025 1.77083 4.71875V5.93047C2.78318 6.17797 3.53656 7.09234 3.53656 8.17974C3.53656 9.26714 2.78318 10.1815 1.77083 10.429V11.5938C1.77083 11.91 2.0275 12.1667 2.34375 12.1667Z" />
          </svg>
        </span>
      ),
      label: t('sidebar.ticketLogs'),
    },
    { key: 'paymentLogs', icon: <CreditCardOutlined />, label: t('sidebar.paymentLogs') },
    { key: 'accessLogs', icon: <LockOutlined />, label: t('sidebar.accessLogs') },
    { key: 'lists', icon: <UnorderedListOutlined />, label: t('sidebar.lists') },
    { key: 'people', icon: <IdcardOutlined />, label: t('sidebar.people') },
    { key: 'devices', icon: <LaptopOutlined />, label: t('sidebar.devices') },
    { key: 'venues', icon: <EnvironmentOutlined />, label: t('sidebar.venues') },
    { key: 'events', icon: <CalendarOutlined />, label: t('sidebar.events') },
    { key: 'reports', icon: <LineChartOutlined />, label: t('sidebar.reports') },
    { key: 'users', icon: <TeamOutlined />, label: t('sidebar.users') },
    {
      key: 'sub1',
      label: t('sidebar.account'),
      icon: <UserOutlined />,
      children: [
        { key: 'settings', label: t('sidebar.settings'), icon: <SettingOutlined /> },
        { key: 'connections', label: t('sidebar.connections'), icon: <LinkOutlined /> },
        { key: 'kiosk', label: t('sidebar.kiosk'), icon: <TabletOutlined /> },
        {
          key: 'ticketGroups',
          label: t('sidebar.ticketGroups'),
          icon: (
            <span className="anticon anticon-credit-card ant-menu-item-icon">
              <svg width="1em" height="1em" viewBox="0 0 15 16" fill="currentColor">
                <path d="M13.5887 6.46696C13.8105 6.42183 14.01 6.30143 14.1533 6.12615C14.2965 5.95087 14.3749 5.73147 14.375 5.50508V3.98214C14.375 3.72166 14.2715 3.47185 14.0873 3.28766C13.9031 3.10348 13.6533 3 13.3929 3H1.60714C1.34666 3 1.09685 3.10348 0.912665 3.28766C0.728477 3.47185 0.625002 3.72166 0.625002 3.98214V5.50508C0.625126 5.73147 0.70346 5.95087 0.846749 6.12615C0.990037 6.30143 1.18948 6.42183 1.41133 6.46696C1.74305 6.53576 2.04093 6.71677 2.25478 6.97953C2.46863 7.24228 2.5854 7.57071 2.5854 7.90949C2.5854 8.24827 2.46863 8.57669 2.25478 8.83945C2.04093 9.1022 1.74305 9.28322 1.41133 9.35201C1.18907 9.39723 0.989307 9.51798 0.845971 9.69377C0.702635 9.86955 0.624559 10.0895 0.625002 10.3163V11.8393C0.625002 12.0998 0.728477 12.3496 0.912665 12.5338C1.09685 12.718 1.34666 12.8214 1.60714 12.8214H13.3929C13.6533 12.8214 13.9031 12.718 14.0873 12.5338C14.2715 12.3496 14.375 12.0998 14.375 11.8393V10.3163C14.3749 10.09 14.2965 9.87055 14.1533 9.69527C14.01 9.51999 13.8105 9.3996 13.5887 9.35446C13.257 9.28567 12.9591 9.10465 12.7452 8.8419C12.5314 8.57915 12.4146 8.25072 12.4146 7.91194C12.4146 7.57316 12.5314 7.24473 12.7452 6.98198C12.9591 6.71923 13.257 6.53821 13.5887 6.46942V6.46696ZM1.60714 10.317C2.16187 10.204 2.6605 9.90274 3.01863 9.4643C3.37676 9.02586 3.57238 8.47714 3.57238 7.91102C3.57238 7.3449 3.37676 6.79618 3.01863 6.35774C2.6605 5.9193 2.16187 5.61808 1.60714 5.50508V3.98214H5.04464V11.8393H1.60714V10.317ZM13.3929 10.317V11.8393H6.02679V3.98214H13.3929V5.50446C12.8381 5.61747 12.3395 5.91869 11.9814 6.35713C11.6232 6.79557 11.4276 7.34429 11.4276 7.91041C11.4276 8.47652 11.6232 9.02525 11.9814 9.46369C12.3395 9.90213 12.8381 10.2033 13.3929 10.3163V10.317Z" />
              </svg>
            </span>
          ),
        },
        { key: 'sites', label: t('sidebar.sites'), icon: <LayoutOutlined /> },
        {
          key: 'emailTemplates',
          label: t('sidebar.emailTemplates'),
          icon: <MailOutlined />,
        },
        { key: 'assets', label: t('sidebar.assets'), icon: <FileOutlined /> },
      ],
    },
  ];
};
