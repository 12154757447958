import { lazy, Suspense } from 'react';

import { createBrowserRouter, RouteObject } from 'react-router-dom';

import { ProtectedRoutes } from '@/components/ProtectedRoutes';

import App from './App';
import SuspenseLoader from './components/SuspenseLoader';
import { UserRole } from './contexts/AuthContext';

// Pages
const NoPermission = lazy(() => import('./pages/NoPermission'));
const NotFound = lazy(() => import('./pages/NotFound'));
const LoginPage = lazy(() => import('@/pages/Login'));
const ListsLanding = lazy(() => import('@/pages/Lists/ListsLanding'));
const ListDetailPage = lazy(() => import('@/pages/Lists/ListDetailPage'));
const ListDetailPeopleAdd = lazy(() => import('@/pages/Lists/ListDetailPeopleAdd'));

const PeoplePage = lazy(() => import('src/pages/People/List'));
const PeoplePageDetails = lazy(() => import('src/pages/People/Details'));

const renderSuspensedComponent = (Component: React.ComponentType) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component />
  </Suspense>
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoutes>{renderSuspensedComponent(App)}</ProtectedRoutes>,
    children: [
      {
        index: true,
        element: <div>Home Page</div>,
      },
      {
        element: <ProtectedRoutes requiredRoles={[UserRole.Admin]} />,
        children: [
          { path: 'users', element: <div>Users</div> },
          { path: 'settings', element: <div>Settings</div> },
        ],
      },
      {
        element: <ProtectedRoutes requiredRoles={[UserRole.Admin, UserRole.Manager]} />,
        children: [{ path: 'reports', element: <div>Reports</div> }],
      },
      {
        element: <ProtectedRoutes />,
        children: [
          { path: 'ticket-logs', element: <div>Ticket Logs</div> },
          { path: 'payment-logs', element: <div>Payment Logs</div> },
          { path: 'access-logs', element: <div>Access Logs</div> },
          {
            path: 'lists',
            element: renderSuspensedComponent(ListsLanding),
            children: [
              {
                path: 'new',
                element: renderSuspensedComponent(ListDetailPage),
              },
              {
                path: ':id',
                element: renderSuspensedComponent(ListDetailPage),
              },
              {
                path: ':id/add-people',
                element: renderSuspensedComponent(ListDetailPeopleAdd),
              },
            ],
          },
          {
            path: 'people',
            element: renderSuspensedComponent(PeoplePage),
          },
          {
            path: 'people/:id',
            element: renderSuspensedComponent(PeoplePageDetails),
          },
          { path: 'venues', element: <div>Venues</div> },
          { path: 'devices', element: <div>Devices</div> },
          { path: 'events', element: <div>Events</div> },
          { path: 'account', element: <div>Account</div> },
          { path: 'settings', element: <div>Settings</div> },
          { path: 'kiosk', element: <div>Kiosk</div> },
          { path: 'connections', element: <div>Connections</div> },
          { path: 'sites', element: <div>Sites</div> },
          { path: 'ticket-groups', element: <div>Ticket Groups</div> },
          { path: 'email-templates', element: <div>Email Templates</div> },
          { path: 'assets', element: <div>Assets</div> },
          {
            path: 'no-permission',
            element: renderSuspensedComponent(NoPermission),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: renderSuspensedComponent(LoginPage),
  },
  {
    path: '*',
    element: renderSuspensedComponent(NotFound),
  },
];
const router = createBrowserRouter(routes);

export { router, routes };
