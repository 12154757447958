import { Spin } from 'antd';

import { FullScreenContainer } from './SuspenseLoader.styled';

const SuspenseLoader = () => (
  <FullScreenContainer>
    <Spin size="large" />
  </FullScreenContainer>
);

export default SuspenseLoader;
