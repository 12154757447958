import { AxiosResponse } from 'axios';
import { FanguardApiContractsUserUserDetailModel } from 'src/api/generated';

import { get } from '@/api/api';
import { useAuth } from '@/contexts/AuthContext';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const URI = 'users';

const useGetUser = (): UseQueryResult<AxiosResponse<FanguardApiContractsUserUserDetailModel>, Error> => {
  const { username } = useAuth();

  return useQuery({
    queryKey: [URI, username],
    queryFn: () => get<FanguardApiContractsUserUserDetailModel>({ uri: `${URI}/${username}` }),
    enabled: !!username,
  });
};

export { useGetUser };
