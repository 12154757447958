import { useAuth0 } from '@auth0/auth0-react';
import { ConfigAuth } from 'src/hooks/api/useConfigEndpoint';
import SuspenseLoader from '../SuspenseLoader';
import axiosInstance from 'src/api/axios/axiosInstance';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

interface Auth0WrapperProps {
  data?: ConfigAuth;
  children: React.ReactNode;
}

const Auth0Wrapper = ({ data, children }: Auth0WrapperProps) => {
  const { isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0();

  const getAuth0Token = async () => {
    try {
      const token = await getAccessTokenSilently();
      axiosInstance.interceptors.request.use(
        (config) => {
          if (token) {
            config.headers.set('Authorization', `Bearer-Auth0 ${token}`);
            config.headers.set('Client', data?.client ?? 'panthers'); // get client from
          }
          return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
          return Promise.reject(error);
        },
      );

      return token;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAuth0Token();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (data?.platform === 'Auth0' && !isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return children;
};

export default Auth0Wrapper;
