import axiosInstance from './axiosInstance';
import { AxiosResponse, AxiosError } from 'axios';
import { APP_PLATFORM_WEB, API_VERSION, CLIENT_DEMO } from './config';
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    config.headers.set('appPlatform', APP_PLATFORM_WEB);
    config.headers.set('apiVersion', API_VERSION);

    if (!config.headers.get('client')) {
      config.headers.set('client', CLIENT_DEMO); // get client from ?
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
