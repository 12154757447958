import { AppHeader } from '../AppHeader/AppHeader';
import AppBreadcrumbs from '../Breadcrumbs';
import { Content, Wrapper } from './Layout.styled';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <Wrapper>
      <AppHeader />
      <AppBreadcrumbs />
      <Content>{children}</Content>
    </Wrapper>
  );
};
