import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;
