import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-drawer-body {
    padding: 0px;
    min-height: 100dvh;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    min-width: 300px;
  }
`;

export const MenuWrapper = styled.div`
  max-width: 80%;
  min-height: 100dvh;
`;
