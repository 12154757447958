import { AxiosResponse } from 'axios';
import { get } from 'src/api/api';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

const URI = 'config';

export interface ConfigAuth {
  client: string;
  clientId: string;
  domain: string;
  platform: string;
}

const useGetConfigAuth = (): UseQueryResult<AxiosResponse<ConfigAuth>, Error> => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  return useQuery({
    queryKey: [subdomain],
    queryFn: () => get<ConfigAuth>({ uri: `${URI}/auth/${subdomain}` }),
    refetchOnWindowFocus: false,
    retry: 1,
  });
};

export { useGetConfigAuth };
