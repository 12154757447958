import React, { createContext, useContext, useState } from 'react';

import { AuthContextType, AuthProviderProps, UserRole } from './AuthContext.types';
import { useAuth0 } from '@auth0/auth0-react';

const AuthContext = createContext<AuthContextType | null>(null);

// in my opinion it should be temporary solution just to allow login and look around the app
const isTokenExpired = (token: string) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const now = Date.now() / 1000;
  return decodedToken.exp < now;
};

const getAuthInfo = () => {
  const token = localStorage.getItem('token');
  if (!token || isTokenExpired(token)) {
    return { isAuthenticated: false, username: null };
  }

  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const username = decodedToken.sub as string;

  return {
    isAuthenticated: true,
    username,
  };
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { isAuthenticated, username } = getAuthInfo();
  const { isAuthenticated: auth0IsAuthenticated, logout: auth0Logout } = useAuth0();

  const [authData, setAuthData] = useState<Omit<AuthContextType, 'login' | 'logout'>>({
    isAuthenticated,
    username,
    roles: [],
  });

  const login = (token: string) => {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const username = decodedToken.sub as string;
    const roles = [UserRole.Admin]; // TODO: get roles from ?
    setAuthData({
      username,
      isAuthenticated: true,
      roles,
    });
  };

  const logoutFromAuth0 = () => {
    if (auth0IsAuthenticated) {
      auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  };

  const logout = () => {
    logoutFromAuth0();
    setAuthData({
      isAuthenticated: false,
      username: null,
      roles: [],
    });
  };

  return <AuthContext.Provider value={{ ...authData, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
