import { AxiosResponse } from 'axios';
import { FanguardApiContractsClientVenueSummaryModel } from 'src/api/generated';

import { get } from '@/api/api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const URI = 'venues';

const useGetVenues = (): UseQueryResult<AxiosResponse<FanguardApiContractsClientVenueSummaryModel[]>, Error> => {
  return useQuery({
    queryKey: [URI],
    queryFn: () => get<FanguardApiContractsClientVenueSummaryModel[]>({ uri: URI }),
  });
};

export { useGetVenues };
