export const matchPath = (pathPattern: string, currentPath: string) => {
  const pathPatternParts = pathPattern.split('/').filter(Boolean);
  const currentPathParts = currentPath.split('/').filter(Boolean);

  if (pathPatternParts.length !== currentPathParts.length) {
    return null;
  }

  const params: Record<string, string> = {};

  for (let i = 0; i < pathPatternParts.length; i++) {
    const patternPart = pathPatternParts[i];
    const currentPart = currentPathParts[i];

    if (patternPart.startsWith(':')) {
      const paramName = patternPart.slice(1);
      params[paramName] = currentPart;
    } else if (patternPart !== currentPart) {
      return null;
    }
  }

  return params;
};
