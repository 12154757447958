import { useMemo, useState } from 'react';

import { Button, Dropdown, Flex, Space, Typography } from 'antd';
import useDeviceType from 'src/utils/useDeviceType';

import { useGetVenues } from '@/hooks/api/useVenuesEndpoint';
import { DownOutlined } from '@ant-design/icons';

import { StyledAnchor } from './AppHeader.styled';

const VenuesDropdown = () => {
  const { data: venuesData, isPending, isError, refetch } = useGetVenues();
  const { isDesktop } = useDeviceType();

  const [selectedVenue, setSelectedVenue] = useState<string | null>(null); // this probably should be a global state

  const menuItems = useMemo(() => {
    return {
      items: venuesData?.data.map((venue) => ({
        key: venue.id || '',
        label: <a onClick={() => handleMenuClick(venue.name)}>{venue.name}</a>,
      })),
    };
  }, [venuesData]);

  const onRefetchVenueHandler = () => refetch();

  const handleMenuClick = (name?: string | null) => {
    if (name) {
      setSelectedVenue(name);
    }
  };
  if (isError) {
    return (
      <Flex align="center" gap={16}>
        <Typography.Text type="danger">Error fetching venues</Typography.Text>
        <Button onClick={onRefetchVenueHandler} disabled={isPending}>
          {isPending ? 'Refetching...' : 'Refetch'}
        </Button>
      </Flex>
    );
  }

  return (
    <Flex justify={isDesktop ? 'start' : 'center'} flex={1}>
      <Dropdown menu={menuItems ?? []}>
        <StyledAnchor>
          <Space>
            {isPending && !selectedVenue ? 'Loading...' : selectedVenue || 'Select Venue'}
            <DownOutlined />
          </Space>
        </StyledAnchor>
      </Dropdown>
    </Flex>
  );
};

export default VenuesDropdown;
